import { Icon, IconProps } from '@mui/material';
import { COLORS } from '../../constants';
import './styles.css';
import { MaterialIconType } from './types';

type MaterialIconModel = IconProps & {
  icon: MaterialIconType;
  containerType?: 'none' | 'rectangle' | 'circle';
  size?: number;
};

const defaultSize = 24;

/**
 * Renders a Material Icon component, extends IconProps
 *
 * @param {MaterialIconType} icon - Icon name from https://fonts.google.com/icons.
 * @param {MaterialIconModel} props - The props for the Icon component.
 * @param {MaterialIconModel['containerType']} containerType - The type of container to use for the icon. default: 'none'.
 * @param {MaterialIconModel['size']} size - The size of the icon.
 * @return {React.ReactElement} The rendered MaterialIcon component.
 */
export const MaterialIcon = ({
  icon,
  containerType = 'none',
  size = defaultSize,
  sx,
  ...props
}: MaterialIconModel): React.ReactElement => {
  return (
    <Icon
      data-testid="material-icon"
      baseClassName="material-symbols-outlined"
      color="inherit"
      sx={{
        display: 'table !important',
        textAlign: 'left',
        fontSize: `${size}px !important`,
        lineHeight: `inherit !important`,
        ...materialIconContainerStyles({ containerType, size }),
        ...sx,
      }}
      {...props}
    >
      {icon}
    </Icon>
  );
};

const materialIconContainerStyles = ({
  containerType,
  size = defaultSize,
}: {
  containerType: MaterialIconModel['containerType'];
  size: MaterialIconModel['size'];
}) => {
  switch (containerType) {
    case 'rectangle':
      return {
        lineHeight: `revert-layer !important`,
        backgroundColor: COLORS.background[1],
        borderRadius: '8px',
        boxShadow: `0px 0px 0px ${size / 6}px ${COLORS.background[0]}`,
        padding: `${size / 5}px`,
      };
    case 'circle':
      return {
        lineHeight: `revert-layer !important`,
        backgroundColor: COLORS.background[1],
        borderRadius: '50%',
        boxShadow: `0px 0px 0px ${size / 6}px ${COLORS.background[0]}`,
        padding: `${size / 5}px`,
      };
    case 'none':
    default:
      return {
        backgroundColor: 'transparent',
      };
  }
};

export * from './types';
