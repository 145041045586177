'use client';
import { Box, Typography } from '@mui/material';
import { MaterialIcon } from '@repo/ui';
import { useRouter } from 'next/navigation';

/**
 * Back arrow icon button that triggers prop function or navigates to previous page when clicked using next navigation router.
 *
 * @param {Function} action - The custom function to trigger.
 * @return {JSX.Element} The rendered back arrow icon button.
 */
export const BackArrow = ({ route, label }: { route?: string; label?: string }) => {
  const router = useRouter();
  return (
    <Box
      display="flex"
      data-testid="backarrow"
      alignItems="center"
      sx={{ cursor: 'pointer', width: 'fit-content' }}
      gap={1}
      onClick={() => (route ? router.push(route) : router.back())}
      p={{ xs: 2, md: 0 }}
    >
      <MaterialIcon icon="arrow_back" color="primary" style={{ lineHeight: 1 }} />
      {label && (
        <Typography variant="body2" color="disabled" display={{ xs: 'none', md: 'block' }}>
          {label}
        </Typography>
      )}
    </Box>
  );
};
