import { Box, BoxProps } from '@mui/material/';
import { Logo } from '../../assets';
import { Transition } from '../transition';

/**
 * Top header with logo, extends MUI Box
 */
export const Header = (props: BoxProps) => {
  const { sx, ...rest } = props;
  return (
    <Box sx={{ backgroundColor: 'background.default', zIndex: 2 }} data-testid="header">
      <Transition type="fadeDown">
        <Box
          component={'header'}
          padding={2}
          boxShadow={5}
          top={0}
          zIndex={1}
          position={'relative'}
          sx={{ display: { xs: 'flex', md: 'none' }, ...sx }}
          {...rest}
        >
          <Logo height={28} />
        </Box>
      </Transition>
    </Box>
  );
};
