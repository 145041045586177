'use client';

import { useEffect, useState } from 'react';

/**
 * Sendpulse snippet
 *
 * @returns {JSX.Element} The Sendpulse snippet.
 */
export const Sendpulse = () => {
  const sendpulseId = process.env.NEXT_PUBLIC_SENDPULSE_ID;
  const [started, setStarted] = useState(false);
  /* c8 ignore start */
  if (!sendpulseId) {
    console.warn('Missing sendpulseId ID, skipping banner');
    return <></>;
  }
  useEffect(() => {
    setStarted(true);
  }, []);
  /* c8 ignore end */
  return (
    <>
      <div id="send_pulse" />
      {started && (
        <script async src="https://static.sppopups.com/assets/loader.js" data-chats-widget-id={sendpulseId} />
      )}
    </>
  );
};

export default Sendpulse;
