export const formatter = (value?: string | number, type?: 'dni' | 'currency' | 'capitalize' | 'date' | 'number') => {
  if (!value && value !== 0) return '';
  const stringValue = `${value}`;
  switch (type) {
    case 'capitalize':
      return (
        stringValue
          ?.replaceAll('_', ' ')
          .split(' ')
          .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
          .join(' ') || ''
      );
    case 'currency':
      return stringValue
        ? new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', minimumFractionDigits: 0 }).format(
            Number(stringValue),
          )
        : '';
    case 'number':
      return stringValue
        ? new Intl.NumberFormat('es-AR', { minimumFractionDigits: 0 }).format(Number(stringValue.replace(',', '.')))
        : '';
    case 'dni':
      return stringValue ? Number(stringValue).toLocaleString().replaceAll(',', '.') : '';
    case 'date':
      return stringValue
        ? new Date(stringValue).toLocaleDateString('es-AR', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            timeZone: 'UTC',
          })
        : '';
    default:
      return stringValue;
  }
};
