'use client';
/* eslint-disable no-unused-vars */
import { AlertOwnerState, Zoom } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Open_Sans } from 'next/font/google';
import localFont from 'next/font/local';
import { COLORS } from './constants';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    inverted: true;
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      nonce: string;
    };
  }
  // allow configuration using `createTheme()`
  interface ThemeOptions {
    custom?: {
      nonce?: string;
    };
  }
  interface TypographyVariants {
    placeholder: React.CSSProperties;
    body3: React.CSSProperties;
    h1withIcon: React.CSSProperties;
    h2withIcon: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    placeholder?: React.CSSProperties;
    body3?: React.CSSProperties;
    h1withIcon: React.CSSProperties;
    h2withIcon: React.CSSProperties;
  }
  interface TableRowVariantsOptions {
    mobileFlex?: React.CSSProperties;
    hideWrapBorders?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    placeholder: true;
    body3: true;
    h1withIcon: true;
    h2withIcon: true;
  }
}

declare module '@mui/material/FormControlLabel' {
  interface FormControlLabelProps {
    variant?: string;
  }
}
declare module '@mui/material/TableRow' {
  interface TableRowOwnProps {
    variant?: string;
  }
}

const openSans = Open_Sans({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});
// const cocogoose = localFont({
//     src: './fonts/Cocogoose-Pro-Variable.ttf',
//     display: 'auto',
// })
const cocogoose = localFont({
  src: [
    {
      path: './fonts/Cocogoose-Pro-Thin.ttf',
      weight: '200',
      style: 'normal',
    },
    {
      path: './fonts/Cocogoose-Pro-Light.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: './fonts/Cocogoose-Pro-Semilight.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/Cocogoose-Pro-Regular.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/Cocogoose-Pro-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
});
/**
 * Default MUI theme to use in all proyects
 */

export const theme = ({ nonce }: { nonce: string }) =>
  createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: COLORS.primary.purple.main,
        light: COLORS.primary.purple.light,
      },
      secondary: {
        main: COLORS.primary.blue.main,
        light: COLORS.primary.blue.light,
      },
      error: {
        main: COLORS.state.error,
      },
      text: {
        primary: COLORS.text.main,
        secondary: COLORS.text.light,
        disabled: COLORS.state.disabled,
      },
      background: { paper: '#fff', default: '#fff' },
    },
    typography: {
      fontFamily: cocogoose.style.fontFamily,
      // fontFamily: openSans.style.fontFamily,
      h1: {
        fontSize: '1.5rem',
        lineHeight: '1.6rem',
        letterSpacing: '-0.025em',
        fontWeight: 700,
        color: COLORS.primary.blue.main,
      },
      h2: {
        fontSize: '1rem',
        lineHeight: '1.4rem',
        letterSpacing: '-0.025em',
        fontWeight: 400,
        color: COLORS.primary.blue.main,
      },
      subtitle1: {
        color: COLORS.primary.purple.main,
        fontSize: '0.75rem',
        fontWeight: 400,
      },
      subtitle2: {
        color: COLORS.text.light,
        fontSize: '.875rem',
        fontFamily: openSans.style.fontFamily,
        fontWeight: 400,
      },
      placeholder: {
        fontSize: '1rem',
        color: COLORS.text.placeholder,
      },
      body3: {
        fontFamily: openSans.style.fontFamily,
        fontSize: '.875rem',
        color: COLORS.text.light3,
      },
      h1withIcon: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
        fontSize: '1.5rem',
        fontWeight: 700,
        fontFamily: cocogoose.style.fontFamily,
        lineHeight: '1.6rem',
        letterSpacing: '-0.025em',
        color: COLORS.primary.blue.main,
      },
      h2withIcon: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
        fontSize: '1rem',
        fontWeight: 400,
        fontFamily: cocogoose.style.fontFamily,
        lineHeight: '1.4rem',
        letterSpacing: '-0.025em',
        color: COLORS.primary.blue.main,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '@media (min-width: 900px) ': {
              // [theme.breakpoints.up('md')]: {  @todo: make ths work
              background: COLORS.background[0],
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState }: { ownerState: AlertOwnerState }) => ({
            ...(ownerState.severity === 'success' && {
              backgroundColor: '#EEFBF2',
              border: '1px solid #02950066',
              color: '#029500',
            }),
            ...(ownerState.severity === 'info' && {
              backgroundColor: '#E5EBF6',
              border: '1px solid #0039A666',
              color: COLORS.primary.blue.main,
            }),
            ...(ownerState.severity === 'error' && {
              backgroundColor: '#FBE7E5',
              border: '1px solid #D70E0066',
              color: '#D70E00',
            }),
            alignItems: 'center',
            '& .MuiAlert-action': { padding: 0, marginLeft: '16px' },
          }),
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'inverted' },
            style: {
              textTransform: 'none',
              color: COLORS.primary.blue.main,
            },
          },
        ],
        defaultProps: {
          disableElevation: true,
          color: 'secondary',
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            minHeight: 44,
            fontWeight: 400,
            textTransform: 'none',
            lineHeight: 1,
          },
          text: {
            color: COLORS.primary.purple.main,
            background: 'none',
            '& .MuiTouchRipple-child': {
              backgroundColor: COLORS.primary.purple.light,
            },
          },
        },
      },
      MuiFormControlLabel: {
        variants: [
          {
            props: { variant: 'error' },
            style: {
              borderColor: COLORS.state.error,
              '&:focus-within': {
                borderColor: COLORS.state.error,
              },
            },
          },
          {
            props: { variant: 'checkbox' },
            style: {
              alignItems: 'center',
              gap: 1,
              pr: 1,
              '&:has(.Mui-checked)': {
                borderColor: COLORS.primary.purple.main,
                backgroundColor: COLORS.background[0],
              },
            },
          },
        ],
        defaultProps: {
          labelPlacement: 'end',
        },
        styleOverrides: {
          label: {
            lineHeight: 'normal',
            fontSize: '0.80rem',
            color: COLORS.text.main,
          },
          root: {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            margin: '0 0 16px',
            borderRadius: 8,
            paddingRight: 12,
            '&:hover': {
              borderColor: COLORS.text.main,
            },
            '&:focus-within': {
              borderColor: COLORS.text.main,
            },
            '& .MuiFormControlLabel-asterisk': {
              display: 'none',
            },
            '& .MuiStack-root': {
              display: 'flex',
            },
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          checkedIcon: (
            <Zoom in>
              <svg
                style={{ margin: '2px' }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#863DFF" />
                <path
                  d="M14.6668 6.5L8.25016 12.9167L5.3335 10"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Zoom>
          ),
        },
        styleOverrides: {
          root: {
            color: COLORS.text.light2,
            '&.Mui-checked ~ .MuiFormControlLabel-label': {
              color: COLORS.text.main,
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          checkedIcon: (
            <Zoom in>
              <svg
                style={{ margin: '2px' }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="6" fill="#863DFF" />
                <path
                  d="M14.6663 6.5L8.24967 12.9167L5.33301 10"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Zoom>
          ),
        },
        styleOverrides: {
          root: {
            color: COLORS.text.light2,
            '&.Mui-checked ~ .MuiFormControlLabel-label': {
              color: COLORS.text.main,
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: COLORS.text.label,
            fontSize: '0.80rem',
            fontWeight: 400,
            position: 'relative',
            transform: 'unset',
            marginBottom: '8px',
            '&.Mui-focused': {
              color: COLORS.text.label,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: COLORS.text.label,
            fontSize: '0.80rem',
            fontWeight: 400,
            position: 'relative',
            transform: 'unset',
            marginBottom: '8px',
            '&.Mui-focused': {
              color: COLORS.text.label,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          InputLabelProps: { shrink: true },
        },
        styleOverrides: {
          root: {
            '& legend': {
              maxWidth: '0%',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
            },
            '&[data-focus="true"] fieldset': {
              borderColor: COLORS.primary.purple.main,
            },
          },
        },
      },
      // MuiFormHelperText: {
      //   styleOverrides: {
      //     root: {
      //       textWrap: 'nowrap',
      //     },
      //   },
      // },
      MuiSelect: {
        defaultProps: {
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '& legend': {
              maxWidth: '0%',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: COLORS.text.main,
            fontSize: '0.75rem',
            fontWeight: 400,
            borderRadius: '8px !important',
            height: 44,
            '&.MuiInputBase-multiline': {
              height: 'unset',
            },
            input: {
              height: 'unset',
              padding: '10px 14px',
              lineHeight: 1,
              fontSize: '1rem',
              '&::placeholder': {
                color: COLORS.text.placeholder,
                opacity: 1,
                fontSize: '1rem',
              },
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            flexWrap: 'nowrap',
            overflow: 'scroll',
            textAlign: 'center',
            '&::-webkit-scrollbar': { display: 'none' },
          },
        },
      },
      MuiSpeedDialAction: {
        styleOverrides: {
          staticTooltipLabel: {
            whiteSpace: 'nowrap',
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            color: COLORS.primary.blue.main,
            '&.Mui-active': {
              boxShadow: '0 0 0 14px #0039A629 !important',
            },
            '&.Mui-focusVisible, &:hover': {
              boxShadow: '0 0 0 8px #0039A629',
            },
          },
          rail: {
            height: '8px',
          },
          track: {
            height: '8px',
          },
          mark: {
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            transform: 'translate(-8px, -50%)',
            '&[data-index="0"]': {
              transform: 'translate(-0px, -50%)',
            },
          },
          // root: {
          //   '& .MuiSlider-mark': {
          //     height: '8px',
          //     width: '8px',
          //     borderRadius: '50%',
          //     '& .MuiSlider-mark:nth-last-child(1)': {
          //       color: 'red !important',
          //       transform: 'translate(-8px, -50%)',
          //     },
          //   },
          // },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            '& .MuiStepLabel-label': {
              '&.Mui-active': { color: COLORS.primary.purple.main },
              '&.Mui-completed': { color: COLORS.primary.purple.main },
            },
            '& .MuiStepLabel-labelContainer': {
              color: COLORS.state.disabled,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            height: '100%',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            color: COLORS.text.light3,
            fontWeight: 400,
            minWidth: 'unset',
            minHeight: 'unset',
            '@media (max-width: 900px) ': {
              fontSize: 0,
              '& .MuiTab-icon': {
                marginRight: '0px',
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            height: '100%',
            aspectRatio: '1',
          },
        },
      },
      MuiTableRow: {
        variants: [
          {
            props: { variant: 'mobileFlex' },
            style: {
              '@media (max-width: 900px) ': {
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                border: `1px solid ${COLORS.background[1]}`,
                borderRadius: '8px',
                backgroundColor: '#fff',
              },
              '& > td': {
                display: 'table-cell',
                alignItems: 'center',
                gap: '0.5rem',
                padding: '1rem',
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                height: '67px',
                '@media (max-width: 900px) ': {
                  height: 'unset',
                  display: 'flex',
                  padding: 0,
                  borderBottom: 'none',
                },
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            display: 'table-row',
            flexDirection: 'row',
            padding: '1rem',
            margin: '1rem',
            gap: 0,
            border: 'none',
            borderRadius: 0,
            backgroundColor: 'transparent',
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            display: 'flex',
            width: '100%',
            padding: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            '& li': {
              '& .MuiPaginationItem-icon': {
                color: COLORS.primary.purple.main,
              },
              '& .Mui-selected': {
                backgroundColor: COLORS.background[0],
                '&:hover': {
                  backgroundColor: COLORS.background[1],
                },
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorInfo: {
            color: '#0039A6',
            backgroundColor: '#0039A61A',
          },
          colorWarning: {
            color: '#C46A0A',
            backgroundColor: '#FFF6ED',
          },
          colorSuccess: {
            color: '#029500',
            backgroundColor: '#EEFBF2',
          },
          colorError: {
            color: '#D70E00',
            backgroundColor: '#FBE7E5',
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            border: `1px solid ${COLORS.background[1]}`,
            borderRadius: '8px',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: 'none',
          },
        },
      },
    },
    custom: {
      nonce,
    },
  });
