import { areaCodes } from './areaCodes';

export const extractAreaCode = (code: string) => areaCodes.find((c) => c === code.slice(0, c.length)) || '';

export const validAreaCode = (code: string) => areaCodes.some((c) => c === code);

export const formatPhone = (phone: string) => {
  const numberWithAreaCode = phone.startsWith('+54') ? phone.slice(3) : phone;
  const areaCode = extractAreaCode(numberWithAreaCode);
  if (areaCode) {
    const numberWithoutAreaCode = numberWithAreaCode.slice(areaCode.length);
    const firstPart = numberWithoutAreaCode.slice(0, Math.floor(numberWithoutAreaCode.length / 2));
    const secondPart = numberWithoutAreaCode.slice(Math.floor(numberWithoutAreaCode.length / 2));
    return `+54 (${areaCode}) ${firstPart} - ${secondPart}`;
  }
  return phone;
};
