import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { MaterialIcon } from '@repo/ui';
import { formatter } from '@repo/utils';

export const OrderCard = ({
  merchantName,
  merchantOrderId,
  price,
}: {
  merchantName: string;
  merchantOrderId: string;
  price: string;
}) => {
  return (
    <Grid
      container
      direction={'column'}
      gap={2}
      sx={{
        width: '100%',
        p: 2,
        borderRadius: 2,
        backgroundColor: 'background.paper',
        border: '1px solid',
        borderColor: 'primary.main',
      }}
    >
      <Grid container direction={'row'} gap={2}>
        <MaterialIcon
          color="primary"
          containerType="circle"
          icon="store"
          size={28}
          sx={{ lineHeight: '1 !important' }}
        />
        <Grid container direction={'column'}>
          <Typography variant="h2" fontSize={18} fontWeight={'500'}>
            {merchantName}
          </Typography>
          <Typography variant="subtitle2">Orden de compra: {merchantOrderId}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container direction={'row'} gap={2} justifyContent={'space-between'}>
        <Typography variant="h2">Valor de la compra</Typography>
        <Typography variant="h2" fontSize={18}>
          {formatter(price, 'currency')}
        </Typography>
      </Grid>
    </Grid>
  );
};
