'use client';
import { Box, Drawer, IconButton, Stack, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { COLORS, Logo, MaterialIcon, Transition } from '@repo/ui';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

/**
 * Renders the main layout of the app, with a side Menu and main content.
 *
 * @param {React.ReactNode} children - The content to be rendered within the layout.
 * @param {React.ReactElement} menu - The menu to be displayed within the drawer.
 * @return {JSX.Element} The main layout of the app.
 */
export const MenuLayout = ({
  children,
  menu,
}: {
  children: React.ReactNode;
  menu: React.ReactElement;
}): JSX.Element => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const isDesktop = useMediaQuery('(min-width:1200px)');
  const [menuWithClose, setMenuWithClose] = useState<JSX.Element>(menu);

  const pathname = usePathname();

  useEffect(() => {
    setOpenDrawer(false);
  }, [pathname]);

  useEffect(() => {
    setMenuWithClose(React.cloneElement(menu, { closeMenu: () => setOpenDrawer(false) }));
  }, [menu, setOpenDrawer]);

  return (
    <Grid
      component={'main'}
      data-testid="menu-layout"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        height: '100%',
        background: '#F3ECFF',
      }}
    >
      <Stack
        component="header"
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          height: '3rem',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
          background: '#fff',
        }}
      >
        <IconButton data-testid="menu-layout-toggle" onClick={() => setOpenDrawer(!openDrawer)}>
          <MaterialIcon icon="menu" color="secondary" />
        </IconButton>
        <Box component={Link} href={'/'} sx={{ display: 'flex', alignItems: 'center' }}>
          <Logo height={22} />
        </Box>
      </Stack>
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>
        <Transition type="fadeLeft">
          <Drawer
            data-testid="menu-layout-drawer"
            PaperProps={{
              'data-testid': 'menu-layout-drawer-paper',
              'aria-hidden': openDrawer ? 'false' : 'true',
              sx: { borderRadius: 0, borderRightColor: COLORS.background[1] },
            }}
            sx={{
              width: { xs: 0, lg: 280 },
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 280,
                // height: { xs: 'calc(100dvh - 3rem)', lg: '100dvh' },
                height: '100dvh',
                boxSizing: 'border-box',
                // mt: { xs: '3rem', lg: 0 },
              },
            }}
            variant={isDesktop ? 'persistent' : 'temporary'}
            anchor="left"
            open={isDesktop ? true : openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            {menuWithClose}
          </Drawer>
        </Transition>
        <Grid sx={{ display: 'flex', minHeight: { xs: 'calc(100dvh - 3rem)', lg: '100dvh' } }} flex={1}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
