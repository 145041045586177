import { Box } from '@mui/material';
import { COLORS } from '../../constants';
import { LogoProps } from './types';

/**
 * SVG Logo
 */
export const Logo = ({ type = 'normal', color = 'normal', height, style = {}, onClick }: LogoProps) => {
  const textColor = color === 'normal' ? COLORS.primary.blue.main : 'white';
  const chevronColor = COLORS.primary.purple.main;
  return type === 'normal' ? (
    <Box
      component="svg"
      onClick={onClick}
      viewBox={`0 0 226 46`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="normalLogo"
      sx={{ height: height || 46, ...style }}
    >
      <g id="normal">
        <path
          id="d"
          d="M29.7568 0.00806281H24.5393C23.7006 0.00806281 23.0192 0.689486 23.0192 1.52816C23.0192 1.54429 23.0192 13.0922 23.0192 13.0922C22.3982 12.5559 21.7168 12.1043 20.9668 11.7334C20.2209 11.3664 19.4024 11.0761 18.5153 10.8665C17.6242 10.6568 16.6686 10.552 15.6485 10.552C12.814 10.552 10.1971 11.2576 7.79805 12.6648C5.39896 14.072 3.49985 15.9832 2.10071 18.3903C0.701582 20.7975 0 23.4264 0 26.2771C0 29.1278 0.701582 31.8373 2.10071 34.2525C3.49985 36.6677 5.39089 38.583 7.77385 40.0023C10.1568 41.4216 12.7817 42.1272 15.6485 42.1272C16.8057 42.1272 17.8783 41.9982 18.8701 41.7361C19.862 41.474 20.7531 41.1111 21.5515 40.6393C22.3498 40.1716 23.0837 39.6071 23.753 38.9539L24.3336 40.6635C24.4344 40.9498 24.5755 41.1555 24.761 41.2805C24.9465 41.4054 25.1884 41.47 25.4908 41.47H29.7568C30.5954 41.47 31.2769 40.7885 31.2769 39.9499V1.52009C31.2769 0.68142 30.5954 0 29.7568 0M22.5313 30.4462C21.8539 31.6357 20.9184 32.5712 19.7249 33.2526C18.5314 33.934 17.2251 34.2727 15.8017 34.2727C14.3784 34.2727 13.0599 33.934 11.9027 33.2526C10.7455 32.5752 9.83828 31.6438 9.18508 30.4583C8.53189 29.2769 8.20529 27.9222 8.20529 26.394C8.20529 24.8658 8.53592 23.5151 9.20121 22.2934C9.86247 21.0676 10.7778 20.1161 11.943 19.4387C13.1083 18.7613 14.4107 18.4185 15.8541 18.4185C17.2976 18.4185 18.604 18.7613 19.7774 19.4508C20.9507 20.1402 21.874 21.0918 22.5434 22.3055C23.2127 23.5232 23.5514 24.886 23.5514 26.394C23.5514 27.902 23.2127 29.2527 22.5313 30.4462Z"
          fill={textColor}
        />
        <path
          id="i"
          d="M44.3132 11.1812H39.1521C38.3134 11.1812 37.632 11.8626 37.632 12.7012V40.0831C37.632 40.9218 38.3134 41.6032 39.1521 41.6032H44.3132C45.1518 41.6032 45.8333 40.9218 45.8333 40.0831V12.7012C45.8333 11.8626 45.1518 11.1812 44.3132 11.1812Z"
          fill={textColor}
        />
        <path
          id="."
          d="M44.3132 0.00830078H39.1521C38.3134 0.00830078 37.632 0.689724 37.632 1.5284V5.9274C37.632 6.76607 38.3134 7.44749 39.1521 7.44749H44.3132C45.1518 7.44749 45.8333 6.76607 45.8333 5.9274V1.5284C45.8333 0.689724 45.1518 0.00830078 44.3132 0.00830078Z"
          fill={textColor}
        />
        <path
          id="r"
          d="M72.9919 12.5637C72.9435 12.346 72.7661 12.1444 72.4637 11.9589C68.9154 10.3138 65.8228 10.7211 64.8592 11.0678C63.8955 11.4106 63.0367 11.8944 62.2786 12.5153C61.5246 13.1363 60.8593 13.8903 60.2908 14.7814L59.1578 11.8621C58.9562 11.3259 58.5691 11.0557 58.0006 11.0557H53.779C52.9403 11.0557 52.2589 11.7372 52.2589 12.5758V39.9577C52.2589 40.7964 52.9403 41.4778 53.779 41.4778H58.9441C59.7828 41.4778 60.4642 40.7964 60.4642 39.9577C60.4642 39.7883 60.444 26.2526 60.444 26.2526C60.444 24.5592 60.7505 23.1036 61.3634 21.8859C61.9762 20.6682 62.7705 19.757 63.7544 19.1441C64.4761 18.6925 66.0849 17.9869 67.4478 18.2046C67.9397 18.2812 69.3106 19.0715 69.5364 19.007C69.7622 18.9384 69.9597 18.7651 70.1291 18.4788L72.8709 13.3218C73.004 13.0355 73.0483 12.7855 72.9959 12.5678"
          fill={textColor}
        />
        <path
          id="e"
          d="M94.8939 12.4674C92.5876 11.1085 89.874 10.4312 86.7531 10.4312C83.85 10.4312 81.2615 11.1489 78.9914 12.5843C76.7173 14.0197 74.9391 15.947 73.6569 18.3703C72.3747 20.7936 71.7336 23.4387 71.7336 26.3095C71.7336 29.1803 72.403 31.7972 73.7456 34.2124C75.0883 36.6276 76.9633 38.5509 79.3704 39.9742C81.7776 41.4016 84.4831 42.1112 87.483 42.1112C89.4627 42.1112 91.3134 41.8088 93.0311 41.204C94.6036 40.6516 97.3938 38.9017 97.3978 38.9017C97.8938 38.5509 98.1639 37.7162 97.6761 36.9743C97.1962 36.2405 95.281 33.5914 95.281 33.5914C95.281 33.5914 95.2729 33.5914 95.2689 33.5914C94.9947 33.1842 94.1601 32.7407 93.3496 33.1479C92.5876 33.5269 91.382 34.1317 90.8901 34.3132C89.9748 34.6478 88.9547 34.8172 87.8337 34.8172C86.7128 34.8172 85.5556 34.5874 84.5274 34.1237C83.4952 33.664 82.5961 33.0027 81.834 32.1479C81.0719 31.2931 80.5115 30.269 80.1607 29.0795C80.1607 29.0795 100.378 29.0836 100.398 29.0836C101.14 29.0836 101.757 28.5513 101.89 27.8457C101.902 27.8296 101.91 27.8175 101.91 27.8094C101.926 27.6038 101.934 27.4022 101.942 27.2046C101.95 26.8941 101.954 26.5877 101.954 26.2853C101.954 23.233 101.353 20.5073 100.156 18.1082C98.9583 15.7092 97.2043 13.8302 94.898 12.4714M80.0881 22.9911C80.3905 21.85 80.8824 20.8662 81.5598 20.0356C82.2372 19.205 83.0436 18.576 83.975 18.1486C84.9064 17.7212 85.9266 17.5075 87.0314 17.5075C88.1362 17.5075 89.2006 17.7212 90.1159 18.1486C91.0312 18.576 91.8013 19.2009 92.4303 20.0235C93.0593 20.846 93.523 21.8339 93.8254 22.9911H80.0881Z"
          fill={textColor}
        />
        <path
          id="c"
          d="M117.253 18.9951C118.459 18.2653 119.769 17.9024 121.176 17.9024C122.301 17.9024 123.309 18.0596 124.208 18.3661C124.958 18.6241 126.466 19.4749 126.487 19.4829C126.757 19.62 127.031 19.7571 127.353 19.7571C127.817 19.7571 128.228 19.5434 128.507 19.2168H128.515L128.543 19.1765C128.583 19.1241 128.624 19.0717 128.656 19.0112L130.95 15.721C131.184 15.4549 131.333 15.1081 131.333 14.721C131.333 14.2775 131.144 13.8783 130.837 13.6001C130.704 13.4792 127.866 11.8099 126.434 11.3139C124.704 10.7172 122.846 10.4229 120.85 10.4229C117.846 10.4229 115.144 11.1365 112.745 12.5599C110.346 13.9872 108.475 15.9105 107.133 18.3338C105.79 20.7571 105.121 23.4142 105.121 26.2972C105.121 29.1801 105.794 31.809 107.145 34.2242C108.495 36.6395 110.366 38.5547 112.757 39.974C115.148 41.3933 117.83 42.0989 120.797 42.0989C122.846 42.0989 124.753 41.7925 126.523 41.1796C128.043 40.6554 129.414 39.9417 130.644 39.0506C130.644 39.0506 130.644 39.0506 130.648 39.0506C130.68 39.0264 130.696 39.0143 130.761 38.9579C131.095 38.6797 131.317 38.2644 131.317 37.7926C131.317 37.462 131.208 37.1556 131.027 36.9056L128.535 33.3412H128.527C128.249 32.9904 127.821 32.7606 127.341 32.7606C126.954 32.7606 126.66 32.9904 126.337 33.1477C126.317 33.1598 124.93 33.9259 124.237 34.1637C123.337 34.4742 122.329 34.6274 121.205 34.6274C119.797 34.6274 118.487 34.2646 117.281 33.5468C116.072 32.8251 115.108 31.8292 114.386 30.551C113.665 29.2769 113.306 27.8576 113.306 26.2972C113.306 24.7367 113.66 23.2691 114.374 21.9949C115.088 20.7208 116.047 19.7168 117.257 18.987"
          fill={textColor}
        />
        <path
          id="t"
          d="M146.753 11.0562H142.652L142.644 1.68565C142.648 1.63323 142.66 1.58081 142.66 1.5284C142.66 0.689724 141.979 0.00830078 141.14 0.00830078C141.116 0.00830078 141.092 0.0163667 141.064 0.0163667L135.979 0.00830078C135.14 0.00830078 134.459 0.689724 134.459 1.5284C134.459 1.54856 134.463 1.56872 134.467 1.58888H134.463L134.511 29.1602C134.511 30.761 134.463 33.9342 136.608 37.3736C137.072 38.1155 139.91 41.6234 139.939 41.6597C140.092 41.8653 140.297 42.0307 140.552 42.1395C140.987 42.325 141.459 42.2887 141.85 42.0871L145.926 39.2606C146.261 39.0227 146.471 38.8574 146.612 38.5268C146.801 38.0913 146.761 37.6196 146.559 37.2284C146.559 37.2284 144.672 34.8818 144.148 34.0391C142.709 31.7327 142.636 28.4425 142.636 28.4425V18.2656L146.773 18.2535C147.583 18.2454 148.237 17.6083 148.273 16.806C148.273 17.185 148.281 15.5318 148.281 12.5763C148.281 11.7376 147.6 11.0562 146.761 11.0562"
          fill={textColor}
        />
        <path
          id="o"
          d="M180.572 18.33C179.136 15.8987 177.189 13.9713 174.721 12.5561C172.253 11.1368 169.548 10.4312 166.592 10.4312C163.637 10.4312 160.976 11.1408 158.504 12.5561C156.028 13.9754 154.077 15.8987 152.641 18.33C151.206 20.7613 150.488 23.4306 150.488 26.3337C150.488 29.2368 151.202 31.8455 152.625 34.2608C154.053 36.676 155.988 38.5872 158.439 39.9984C160.887 41.4056 163.588 42.1112 166.54 42.1112C169.491 42.1112 172.185 41.4056 174.669 39.9984C177.152 38.5912 179.112 36.676 180.555 34.2608C181.999 31.8455 182.721 29.2045 182.721 26.3337C182.721 23.4628 182.003 20.7654 180.567 18.33M173.459 30.3335C172.753 31.5391 171.794 32.4987 170.576 33.2003C169.358 33.9059 168.04 34.2567 166.612 34.2567C165.185 34.2567 163.846 33.9059 162.637 33.2003C161.427 32.4947 160.468 31.5391 159.754 30.3335C159.04 29.1239 158.685 27.7772 158.685 26.2813C158.685 24.7854 159.04 23.4306 159.754 22.2048C160.468 20.9791 161.423 20.0235 162.621 19.338C163.818 18.6485 165.149 18.3058 166.608 18.3058C168.068 18.3058 169.374 18.6485 170.584 19.338C171.794 20.0275 172.749 20.9831 173.451 22.2048C174.156 23.4306 174.507 24.7894 174.507 26.2813C174.507 27.7731 174.156 29.1239 173.451 30.3335"
          fill={textColor}
        />
      </g>
      <g id="chevron">
        <path
          id=">1"
          d="M207.116 26.5228C207.116 26.4825 207.12 26.4422 207.12 26.4059C207.12 26.3696 207.12 26.3253 207.112 26.289C207.108 26.2164 207.096 26.1398 207.08 26.0672C207.08 26.0632 207.08 26.0551 207.076 26.0511C207.015 25.789 206.89 25.543 206.697 25.3414C205.406 23.6036 194.108 8.40267 194.016 8.28171C193.516 7.60835 192.564 7.46723 191.891 7.96721C191.762 8.06398 187.875 10.9509 187.746 11.0477C187.072 11.5477 186.931 12.4993 187.431 13.1726C187.5 13.2613 193.504 21.3416 197.266 26.4019C193.504 31.4662 187.5 39.5424 187.431 39.6352C186.931 40.3085 187.072 41.2601 187.746 41.7601C187.875 41.8568 191.762 44.7438 191.891 44.8406C192.564 45.3406 193.516 45.1994 194.016 44.5261C194.108 44.4051 205.402 29.2042 206.697 27.4664C206.89 27.2647 207.015 27.0188 207.076 26.7567C207.076 26.7527 207.076 26.7446 207.076 26.7406C207.092 26.668 207.104 26.5954 207.108 26.5188"
          fill={chevronColor}
        />
        <path
          id=">2"
          d="M225.981 26.5228C225.981 26.4825 225.986 26.4422 225.986 26.4059C225.986 26.3696 225.986 26.3253 225.977 26.289C225.973 26.2164 225.961 26.1398 225.945 26.0672C225.945 26.0632 225.945 26.0551 225.941 26.0511C225.881 25.789 225.756 25.543 225.562 25.3414C224.272 23.6036 212.974 8.40267 212.881 8.28171C212.381 7.60835 211.43 7.46723 210.756 7.96721C210.627 8.06398 206.74 10.9509 206.611 11.0477C205.938 11.5477 205.797 12.4993 206.297 13.1726C206.365 13.2613 212.369 21.3416 216.131 26.4019C212.369 31.4662 206.365 39.5424 206.297 39.6352C205.797 40.3085 205.938 41.2601 206.611 41.7601C206.74 41.8568 210.627 44.7438 210.756 44.8406C211.43 45.3406 212.381 45.1994 212.881 44.5261C212.974 44.4051 224.268 29.2042 225.562 27.4664C225.756 27.2647 225.881 27.0188 225.941 26.7567C225.941 26.7527 225.941 26.7446 225.941 26.7406C225.957 26.668 225.969 26.5954 225.973 26.5188"
          fill={chevronColor}
        />
      </g>
    </Box>
  ) : (
    <Box
      component="svg"
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="smallLogo"
      sx={{ height: height || 30, ...style }}
    >
      <g id="small">
        <path
          fill={textColor}
          d="M12.7,6.5h-2.2c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,5,0,5c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.7-0.3-1.1-0.4
		C7.6,11.1,7.1,11,6.7,11c-1.2,0-2.3,0.3-3.4,0.9c-1,0.6-1.8,1.4-2.4,2.5c-0.6,1-0.9,2.2-0.9,3.4c0,1.2,0.3,2.4,0.9,3.4
		c0.6,1,1.4,1.9,2.4,2.5c1,0.6,2.1,0.9,3.4,0.9c0.5,0,1-0.1,1.4-0.2c0.4-0.1,0.8-0.3,1.1-0.5c0.3-0.2,0.7-0.4,0.9-0.7l0.2,0.7
		c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.8c0.4,0,0.7-0.3,0.7-0.7V7.2C13.4,6.8,13.1,6.5,12.7,6.5 M9.7,19.6
		c-0.3,0.5-0.7,0.9-1.2,1.2s-1.1,0.4-1.7,0.4c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1.1-0.4-1.7
		c0-0.6,0.1-1.2,0.4-1.8c0.3-0.5,0.7-0.9,1.2-1.2c0.5-0.3,1.1-0.4,1.7-0.4s1.2,0.1,1.7,0.4c0.5,0.3,0.9,0.7,1.2,1.2
		c0.3,0.5,0.4,1.1,0.4,1.8C10.1,18.5,9.9,19,9.7,19.6"
        />
        <path
          fill={chevronColor}
          d="M24.5,17.8C24.5,17.8,24.5,17.8,24.5,17.8c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.2-0.2-0.3
		c-0.6-0.7-5.1-6.9-5.2-6.9c-0.2-0.3-0.6-0.3-0.9-0.1c-0.1,0-1.7,1.3-1.8,1.3c-0.3,0.2-0.3,0.6-0.1,0.9c0,0,2.3,3.1,3.9,5.3l0,0l0,0
		c-1.6,2.2-3.9,5.3-3.9,5.3c-0.2,0.3-0.2,0.7,0.1,0.9c0.1,0,1.7,1.3,1.8,1.3c0.3,0.2,0.7,0.2,0.9-0.1c0-0.1,4.6-6.2,5.2-6.9
		c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0C24.5,17.9,24.5,17.9,24.5,17.8C24.5,17.9,24.5,17.8,24.5,17.8C24.5,17.8,24.5,17.8,24.5,17.8
		C24.5,17.8,24.5,17.8,24.5,17.8"
        />
        <path
          fill={chevronColor}
          d="M32,17.8C32,17.8,32,17.8,32,17.8c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.2-0.2-0.3
		c-0.6-0.7-5.1-6.9-5.2-6.9c-0.2-0.3-0.6-0.3-0.9-0.1c-0.1,0-1.7,1.3-1.8,1.3c-0.3,0.2-0.3,0.6-0.1,0.9c0,0,2.3,3.1,3.9,5.3l0,0l0,0
		c-1.6,2.2-3.9,5.3-3.9,5.3c-0.2,0.3-0.2,0.7,0.1,0.9c0.1,0,1.7,1.3,1.8,1.3c0.3,0.2,0.7,0.2,0.9-0.1c0-0.1,4.6-6.2,5.2-6.9
		c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0C32,17.9,32,17.9,32,17.8C32,17.9,32,17.8,32,17.8C32,17.8,32,17.8,32,17.8
		C32,17.8,32,17.8,32,17.8"
        />
      </g>
    </Box>
  );
};
