'use client';

import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Dropdown } from '../../dropdown';
import { MaterialIcon, MaterialIconType } from '../../materialIcon';

export type filterField = {
  name: string;
  label: string;
  type?: 'text' | 'date' | 'select' | 'selectMultiple'; // @todo: | 'number' | 'boolean';
  placeholder?: string;
  startIcon?: {
    icon: MaterialIconType;
    color?: string;
  };
  options?: { label: string; value: string }[];
};

/**
 * A component that renders a list of filters.
 *
 * The component will render a text field for each filter, with the
 * label and type of each filter as specified in the `fields` prop.
 *
 * When the user types a value in one of the text fields, the component will
 * update the URL search parameters accordingly.
 *
 * Actual filter logic is handled on the table component.
 *
 * @param {filterField[]} fields - An array of objects with `name`, `label`,
 * and `type` properties.
 */
export const Filters = ({ fields }: { fields: filterField[] }) => {
  const searchParams = useSearchParams();
  const [values, setValues] = useState<{ [key: string]: string }>(
    Array.from(searchParams.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  );

  useEffect(() => {
    const current = new URLSearchParams(Array.from(searchParams.entries()));
    fields.forEach(({ name }) => {
      /* c8 ignore start */
      if (values[name]) current.set(name, values[name] || '');
      /* c8 ignore end */ else current.delete(name);
    });
    if (current.size || searchParams.toString() !== current.toString()) {
      window.history.replaceState(null, '', `?${current.toString()}`);
    }
  }, [values, fields, searchParams]);

  const reset = () => {
    setValues({});
  };

  return (
    <Grid
      spacing={2}
      container
      alignItems={'end'}
      sx={{ my: 2, flexDirection: { xs: 'column', md: 'row' } }}
      borderRadius={2}
      bgcolor="#F9F7FF"
      border="1px solid #E9DCFF"
      p={2}
    >
      <Grid spacing={2} container flexDirection={'row'} size={'grow'} width="100%">
        {fields.map(({ name, label, type = 'text', options, placeholder, startIcon }) => (
          <Grid size={{ xs: 12, md: fields.length < 3 ? 'grow' : 4 }} key={name}>
            {type === 'date' && (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  slotProps={{ textField: { placeholder: '' } }}
                  value={values[name] ? dayjs(values[name]) : null}
                  label={label}
                  data-testid={name}
                  name={name}
                  sx={{ '& .MuiInputBase-root': { backgroundColor: 'white' } }}
                  onChange={(date) => setValues((prev) => ({ ...prev, [name]: dayjs(date).format('YYYY-MM-DD') }))}
                />
              </LocalizationProvider>
            )}
            {type === 'text' && (
              <TextField
                autoComplete="cc-csc"
                value={values[name] || ''}
                label={label}
                placeholder={placeholder || ''}
                data-testid={name}
                name={name}
                type={type || 'text'}
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: 'white',
                  },
                }}
                slotProps={{
                  input: {
                    startAdornment: startIcon && (
                      <MaterialIcon icon={startIcon.icon} sx={{ color: startIcon?.color || '' }} />
                    ),
                  },
                }}
                onChange={(event) => setValues((prev) => ({ ...prev, [name]: event.target.value }))}
              />
            )}
            {type === 'select' && options && (
              <Dropdown
                value={values[name] || ''}
                label={label}
                placeholder={placeholder || ''}
                name={name}
                data-testid={name}
                options={options}
                sx={{ backgroundColor: 'white' }}
                onChange={(event) => setValues((prev) => ({ ...prev, [name]: event.target.value as string }))}
              />
            )}
            {type === 'selectMultiple' && options && (
              <Dropdown
                multiple
                value={(values[name]?.length || 0) > 0 ? values[name]?.split('|') : []}
                label={label}
                name={name}
                sx={{ backgroundColor: 'white' }}
                data-testid={name}
                renderValue={(selected) =>
                  (selected as string[]).length > 0 ? (selected as string[]).join(', ') : 'Todos seleccionados'
                }
                options={options}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setValues((prev) => ({ ...prev, [name]: (value as string[]).join('|') }));
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Button data-testid="filter-reset" variant="outlined" onClick={reset} sx={{ width: { xs: '100%', md: 'unset' } }}>
        Limpiar
      </Button>
    </Grid>
  );
};
