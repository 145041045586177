'use client';

import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Footer, Header, Logo, SafeSpace, Transition } from '@repo/ui';

/**
 * Renders the main layout of the app, which includes a header, main content, and footer.
 *
 * @param {React.ReactNode} children - The content to be rendered within the layout.
 * @return {JSX.Element} The main layout of the app.
 */
export const OnbLayout = ({
  children,
  subtitle,
}: {
  children: React.ReactNode;
  subtitle?: string | React.ReactElement;
}): JSX.Element => {
  return (
    <Grid
      component={'main'}
      data-testid="app-layout"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100dvh',
        background: {
          xs: 'linear-gradient(180deg, #F3ECFF 0%, rgba(243, 236, 255, 0.825) 67.5%, rgba(243, 236, 255, 0.82) 48%, rgba(243, 236, 255, 0.78) 100%)',
          // xs: `linear-gradient(180deg, #F3ECFF 0%, rgba(243, 236, 255, 0.825) 67.5%, rgba(243, 236, 255, 0.82) 48%, rgba(243, 236, 255, 0.78) 100%), url(${process.env.NEXT_PUBLIC_CDN_ROUTE}assets/images/background_doodles.svg)`,
        },
      }}
    >
      <Header />
      <Container
        sx={{
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: { xs: 'center', md: 'space-between' },

          gap: 4,
          background: {
            // xs: '#fff',
            xs: 'transparent',
          },
          px: { xs: 0, md: 4 },
          py: { xs: 0, md: 8 },
          flex: 1,
        }}
      >
        <Grid sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', pt: 6, gap: 6 }}>
          <Transition type="fadeLeft">
            <Grid sx={{ height: { md: 52, lg: 64 } }}>
              <Logo style={{ height: '100%' }} />
            </Grid>
          </Transition>
          <Transition type="fadeLeft" delay={0.2}>
            <Typography variant="h1" sx={{ fontSize: { md: 32, lg: 42 }, lineHeight: 1.2 }}>
              {subtitle}
            </Typography>
          </Transition>
        </Grid>
        <SafeSpace>{children}</SafeSpace>
      </Container>
      <Footer />
    </Grid>
  );
};
