'use client';

import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { apiResponse } from '@repo/api';
import { BackArrow } from '@repo/ui';
import { formatter } from '@repo/utils';
import { useState } from 'react';

type credentialType = 'username' | 'documentNumber';
type RetrieveActivationProps<T> = {
  credentialType?: credentialType;
  defaultValue?: string;
  connector?: (_data: { documentNumber: string }) => Promise<apiResponse<T>>;
  callback?: (_connectorResponse: T) => void;
};

/**
 * A reusable activation kickstart component that dispatches user activation flow.
 *
 * @param {Object} connector - A function that connects to an API to authenticate the user.
 * @param {string} defaultValue - The default value for the activation form @default ''.
 * @param {Function} callback - An optional callback function to handle the API response.
 * @return {JSX.Element} A JSX element representing the login form.
 */
export const RetrieveActivation = <T,>({
  connector,
  callback,
  defaultValue = '',
  credentialType = 'documentNumber',
}: RetrieveActivationProps<T>) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState({ show: false, message: '' });
  const [loading, setLoading] = useState(false);

  // Validation functions for different fields
  const validateDocument = (doc: string) => /^\d{7,10}$/.test(doc);

  // Function that validates each field on blur
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') return;
    let error = '';

    if (credentialType === 'documentNumber' && !validateDocument(value.replace(/\D/g, ''))) {
      error = 'Ingresa un DNI válido';
    }

    setError({ show: !!error, message: error });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    connector?.({
      documentNumber: username,
    }).then((connectorResponse) => {
      if (connectorResponse.softError) {
        setLoading(false);
        setError({
          show: true,
          message:
            connectorResponse.message ||
            (credentialType === 'username' ? 'Ingresa un nombre de usuario válido' : 'Ingresa un DNI válido'),
        });
      } else {
        callback?.(connectorResponse);
      }
    });
  };
  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit}
      data-testid="retrieve-form"
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
    >
      <Stack gap={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <BackArrow route="/login" />
          <Typography variant="h1">Activar cuenta</Typography>
        </Box>
        {/* @TODO: Missing validations */}
        {/* @TODO: Missing format */}
        {credentialType === 'username' ? (
          <TextField
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            label="Nombre de usuario"
            name="username"
            autoFocus
            placeholder="Ej: juan.perez"
            onFocus={() => setError({ show: false, message: '' })}
            onBlur={handleBlur}
            error={error.show}
            helperText={error.show ? error.message : ''}
            slotProps={{ htmlInput: { inputMode: 'string', autoComplete: 'username', 'data-testid': 'username' } }}
          />
        ) : (
          <TextField
            onChange={(e) => setUsername(e.target.value.replace(/\D/g, ''))}
            onFocus={() => setError({ show: false, message: '' })}
            onBlur={handleBlur}
            label="DNI"
            name="username"
            autoFocus
            placeholder="Ej: 12345678"
            slotProps={{ htmlInput: { maxLength: 10, inputMode: 'numeric', 'data-testid': 'documentNumber' } }}
            error={error.show}
            helperText={error.show ? error.message : ''}
            defaultValue={defaultValue}
            value={formatter(username, 'dni')}
          />
        )}
      </Stack>
      <Button type="submit" disabled={loading} fullWidth variant="contained" data-testid="login">
        {loading ? (
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} />
            Cargando...
          </Box>
        ) : (
          'Activar'
        )}
      </Button>
    </Box>
  );
};
