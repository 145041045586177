import { Box, BoxProps } from '@mui/material/';
import Grid from '@mui/material/Grid2';
import { Transition } from '../transition';

/**
 * Renders a safe space component with a maximum width of 540px on medium screens,
 * a background color of 'background.paper', and padding of 0 on extra small screens
 * and 8 on medium screens. The padding top is 4 on extra small screens. The border
 * radius is 6. The component is a section element. The rest of the props are spread
 * onto the Box component. The children are rendered inside the Box component.
 *
 * @param {BoxProps} props - The props for the SafeSpace component.
 * @param {ReactNode} props.children - The children to be rendered inside the Box component.
 * @returns {JSX.Element} The rendered SafeSpace component.
 */
export const SafeSpace = ({ children, ...rest }: BoxProps) => {
  return (
    <Grid
      container
      sx={{
        justifyContent: { xs: 'center', md: 'end' },
        overflow: 'hidden',
        maxWidth: '540px',
        width: { xs: '100vw', lg: '100%' },
      }}
      data-testid="safeSpace"
    >
      <Transition
        id="safeSpace_transition"
        type="fade"
        style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
      >
        <Box
          component={'section'}
          sx={{
            minHeight: { md: '600px' },
            maxHeight: { md: 'fit-content' },
            width: { xs: '100%', md: '540px' },
            backgroundColor: 'background.paper',
            borderRadius: { xs: '0', md: '24px' },
            ...rest.sx,
          }}
          {...rest}
        >
          {children}
        </Box>
      </Transition>
    </Grid>
  );
};
export const SafeSpacePadder = ({ children, ...rest }: BoxProps) => (
  <Box
    data-testid="safeSpacePadder"
    sx={{ width: '100%', padding: { xs: 0, md: 8 }, pt: { xs: 4 }, px: { xs: 2, md: 8 }, ...rest.sx }}
  >
    {children}
  </Box>
);
